<div class="landing-body">
    <div class="landing-wrapper">

        <div class="header-content " >
            <div class="grid" style="margin:0px !important;">
                <div class="col-12" style="padding: 1rem 0;">

                    <section style="display : flex;">
                        <div class="grid" style="margin:0 !important; width:100%;">
                            <div class="col-10 col-offset-1 lg:col-4"
                                 style="position:relative; padding-top:9vw">
                                <span class="welcomeMessage keyword tn-title-huge">
                                    <div>BOOKING</div> <div style="white-space: nowrap">A STUDIO</div><div style="white-space: nowrap">IS EASY.</div>
                                </span>
                            </div>

                            <div class="col-12 lg:col-7" style="text-align:center">
                                <img id = "homepage_main_image" alt="Card"
                                     src="../../assets/images/landing/homepage_campaign.png">
                            </div>
                        </div>
                    </section>

<!--                    <div class="col-12 lg:col-1 lg:col-offset-1 arrow-align">-->
<!--                        <button-->
<!--                                pButton-->
<!--                                icon="till-noon-icon tnicon-Arrow-scroll"-->
<!--                                class="tn-primary-button arrow-scroll"-->
<!--                                (click)="scrollToEl(features)"-->
<!--                        ></button>-->
<!--                    </div>-->
                </div>

                <div class="col-12 md:hidden text-center">
                    <button
                            pButton
                            class="mr-3 tn-secondary-button "
                            [routerLink]="'/listing'"
                            label="BOOK A STUDIO"
                            style="width:auto;"
                            icon="till-noon-icon tnicon-Wave"
                    ></button>
                </div>


                <div class="col-12 lg:col-5 lg:col-offset-7 sticker-div-height" style="padding: 1rem 0;">

                    <img alt="Card" src="../../assets/images/landing/b_sticker%201.png" id = "sticker-1">

                    <img alt="Card" src="../../assets/images/landing/we_are_bb_stamp%201.png" id = "sticker-2">
                </div>
            </div>
        </div>

        <div #features id="features" >
            <div style="display:flex;">
                <h1 class="p-0 m-0 keyword how-it-works" style="font-weight: 200 !important; flex:auto;"> <span class="tn-title-big">How</span><span class="tn-title-big" style="font-weight:400"> it works</span></h1>

                <button
                        pButton
                        class="mr-3 tn-secondary-button display"
                        [routerLink]="'/listing'"
                        label="BOOK A STUDIO"
                        icon="till-noon-icon tnicon-Wave"
                ></button>
            </div>
            <div class="grid" style="display:inherit">
                <p-carousel [numVisible]="3"  [value]="featuresItems" [circular]="true" [responsiveOptions]="responsiveOptions" [autoplayInterval]="10000"
                            [showIndicators]="true" [showNavigators] = false>
                    <ng-template let-feature pTemplate="item">
                        <div class="feature-item feature-{{feature.index}}">
                                <img alt="Card" [src]="feature.image" style="
                            width:316px;height:317px;
                            position: sticky;">

                                <h3> <span style="color:  #00B6B2;
                                    font-size: 28px;
                                    font-style: normal;
                                    font-weight: 800;
                                    line-height: normal;"> {{feature.index}}.
                                </span>
                                <span class="tn-title">{{feature.title}}
                                </span>

                                </h3>

                                <p class="tn-text-big tn-text-green">
                                    {{feature.caption}}
                                </p>

                                <span class="tn-text-simple feature-title">
                                {{feature.body}}
                            </span>
                        </div>
                    </ng-template>
                </p-carousel>

                <div class="col-12 md:hidden text-center">
                    <button
                            pButton
                            class="mr-3 tn-secondary-button "
                            [routerLink]="'/listing'"
                            label="BOOK A STUDIO"
                            style="width:auto;"
                            icon="till-noon-icon tnicon-Wave"
                    ></button>
                </div>
            </div>

        </div>

        <div id="statistics" style="padding-left:7vw;padding-right:7vw;padding-top:78px;padding-bottom:85px;margin-top:-50px;" >
            <div class="grid" >

                <h1 class="col-12 lg:col-6 pt-0 pb-0 m-0 keyword" style="font-weight: 200 !important; flex:auto;"> <span class="tn-title">Featured</span><span class="tn-title" style="font-weight:400;"> studios</span></h1>

                <div class="col-12 lg:col-6 flex lg:justify-content-end">
                    <button
                            pButton
                            class="search-studios-btn mr-3 tn-primary-button"
                            [routerLink]="'/listing'"
                            label="SEARCH ALL STUDIOS"
                    ></button>
                </div>


            </div>

            <div class="grid" style="padding-top: 60px" *ngIf="nearStudios.length > 0 && !fetchingStudios" >
                <div class="col-12 lg:col-4">

                    <app-tn-studio-card
                        [studio]="nearStudios[0]"
                    ></app-tn-studio-card>

                </div>


                <div class="col-12 lg:col-4">

                    <app-tn-studio-card
                            [studio]="nearStudios[1]"
                    ></app-tn-studio-card>

                </div>


                <div class="col-12 lg:col-4">


                    <app-tn-studio-card
                            [studio]="nearStudios[2]"
                    ></app-tn-studio-card>

                </div>
            </div>
            <div *ngIf="nearStudios.length === 0 || fetchingStudios" class="grid progress-spinner-container" style="padding-top: 60px">
                <p-progressSpinner ariaLabel="loading"
                                   animationDuration=".5s"
                                   strokeWidth="8"
                                   class="add-shadow"
                />
            </div>



            <div class="grid" style="padding-top: 60px">
                <div class="col-10 lg:col-6 " >
                    <section class = "how-it-started-section">
                        <p class = "how-it-started-header">
                            It all started in a jam session.
                        </p>

                        <p class = "how-it-started tn-text-simple">
                            You know it. You must have felt it too. That magic moment when the music you create with your bandmates drives your feelings and shakes the whole room. A glimpse to your bass player, enough to let each other know "we're rolling".
                        </p>

                        <p class = "how-it-started tn-text-simple">
                            We have one passion and that is all. Music. We <span style="color: red;">♥</span> music. We love playing it, we love listening to it. We made Bandbeat solely because we want to inspire you to create more and more! To be honest, we made Bandbeat for us. But then, you are like us. We made Bandbeat for you.
                        </p>

                        <p class = "how-it-started" style="font-size:1rem;line-height:0.75rem;letter-spacing:0.02rem;font-weight: 800;">
                            You are Bandbeat. We are Bandbeat.
                        </p>

                        <p class = "how-it-started" style="font-size:1rem;line-height:0.75rem;letter-spacing:0.02rem;font-weight: 800;">
                            We are music.
                        </p>
                    </section>
                </div>
            </div>


            </div>
    </div>
</div>



