<!--<div class="scroll-container "  [ngClass]="{'display-hidden' : scrolled}">-->
<!--    <div class="scroll-text">Full rehearsal. Half the price. >>> <strong>50%</strong> off your first rehearsal with the code:<strong>BANDMATE50</strong></div>-->
<!--</div>-->

<div class="layout-topbar" [ngClass]="{'topbarScrolled' : scrolled}" style="">
    <div class="layout-topbar-left">
        <a class="app-name" [routerLink]="['/']">
            <img alt="main logo" [src]="'assets/images/bandbeat/bb_logo_header.png'" class="app-logo">
            <span>Bandbeat</span>
        </a>

<!--        <a-->
<!--                *ngIf="showMenuHamburger()"-->
<!--                href="#"-->
<!--                class="menu-button"-->
<!--                (click)="appMain.onMenuButtonClick($event)"-->
<!--        >-->
<!--&lt;!&ndash;            <div class="menu-button-icon"></div>&ndash;&gt;-->
<!--            <i-->
<!--                    [ngClass]="{'pi-search' : !appMain.staticMenuMobileActive , 'pi-times' : appMain.staticMenuMobileActive}"-->
<!--                    class="pi"-->
<!--                    style="font-size:x-large"-->
<!--            ></i>-->

<!--        </a>-->
    </div>


    <div class="mobile-display-hidden">
        <a [href]="env.blogUrl" target="_blank">
        <p-button
                class="mr-3"
                label="OUR BLOG"
                styleClass="tn-helper-button"
        ></p-button>
        </a>

        <a [href]="env.studioUrl" target="_blank">
        <p-button
                label="FOR STUDIOS"
                styleClass="tn-helper-button"
        ></p-button>
        </a>
    </div>

    <div class="large-display-hidden">
        <app-menu></app-menu>

        <div class="layout-topbar-right">

            <a

                    *ngIf="loggedIn && notifications | async as notificationsData "
                    class="notifications-link pr-4"
                    [routerLink]="notificationLink"
                    routerLinkActive="topbar-active-menuitem-routerlink"
            >

                    <span>
                        <i
                                *ngIf="notificationsData && notificationsData.length > 0"
                                pBadge
                                [value]="notificationsData.length + ''"
                                class="material-icons">notifications</i>
                        <i
                                *ngIf="!(notificationsData && notificationsData.length > 0)"
                                class="material-icons">notifications</i>
                    </span>
                <span class="active-bottom"></span>
            </a>

            <a href="#"
               class="menu-button"
               (click)="appMain.onMenuButtonClick($event, 'topBar')"
            >
                <!--            <div class="menu-button-icon"></div>-->
                <i
                        [ngClass]="{'till-noon-icon tnicon-Burger' : !appMain.staticMenuMobileActive , 'pi-times' : appMain.staticMenuMobileActive}"
                        class="pi"
                        style="font-size:x-large"
                ></i>
            </a>
        </div>
    </div>

    <div class="mobile-display-hidden layout-topbar-right layout-topbar-left">
        <ul class="layout-topbar-main-menu " *ngIf="!loggedIn">
            <li>

                    <button
                            pButton
                            class="mr-3 tn-primary-button"
                            [routerLink]="'/listing'"
                            label="FIND A STUDIO"
                            icon="till-noon-icon tnicon-Wave"
                            routerLinkActive="tn-primary-button-active"
                    ></button>

<!--                <a [routerLink]="['/login']" routerLinkActive="topbar-active-menuitem-routerlink">Sign In-->
<!--                    <span class="active-bottom"></span>-->
<!--                </a>-->
            </li>
            <li>
                    <button
                            pButton
                            class="mr-3 tn-primary-button"
                            [routerLink]="'/login'"
                            label="LOG IN"
                            icon="till-noon-icon tnicon-User"
                            routerLinkActive="tn-primary-button-active"
                    ></button>

<!--                <a [routerLink]="['/signup']" routerLinkActive="topbar-active-menuitem-routerlink">Sign Up-->
<!--                    <span class="active-bottom"></span>-->
<!--                </a>-->
            </li>
        </ul>

        <ul class="layout-topbar-actions" *ngIf="loggedIn">
            <li>

                <button
                        pButton
                        class="mr-3 tn-primary-button"
                        [routerLink]="'/listing'"
                        label="FIND A STUDIO"
                        icon="till-noon-icon tnicon-Wave"
                        routerLinkActive="tn-primary-button-active"
                ></button>
            </li>

<!--            <li-->
<!--                *ngIf="(shouldShowWizard | async)"-->
<!--                pTooltip="Edit your profile through wizard!"-->
<!--                tooltipPosition="left"-->
<!--            >-->
<!--                <a-->
<!--                    class="notifications-link contract-error"-->
<!--                    [routerLink]="['/wizard']"-->
<!--                >-->
<!--                    <span>-->
<!--                        <i-->
<!--                            class="material-symbols-outlined">video_settings</i>-->
<!--                    </span>-->
<!--                    <span class="active-bottom"></span>-->
<!--                </a>-->
<!--            </li>-->

            <li *ngIf="notifications | async as notificationsData">


                <a
                    class="notifications-link"
                    (click)="op.toggle($event)"
                    *ngIf="notificationsData && notificationsData.length > 0"
                >

                    <span>
                        <i
                            *ngIf="notificationsData && notificationsData.length > 0"
                            pBadge
                            [value]="notificationsData.length + ''"
                            class="material-icons">notifications</i>
                        <i
                            *ngIf="!(notificationsData && notificationsData.length > 0)"
                            class="material-icons">notifications</i>
                    </span>
                    <span class="active-bottom"></span>
                </a>


                <a
                    class="notifications-link"
                    [routerLink]="notificationLink"
                    routerLinkActive="topbar-active-menuitem-routerlink"
                    *ngIf="!(notificationsData && notificationsData.length > 0)"
                >
                    <span>
                        <i
                                *ngIf="!(notificationsData && notificationsData.length > 0)"
                                class="material-icons">notifications</i>
                    </span>
                    <span class="active-bottom"></span>
                </a>




                <p-overlayPanel #op>
                    <ng-template pTemplate>

                        <p-dataView #dv
                                    [value]="notificationsData.slice(0,4)"
                                    [rows]="4"
                                    [totalRecords]="4"
                                    styleClass="dataview-grid"
                                    layout="list">

                            <ng-template let-notification pTemplate="listItem">


                                <div class="col-12" [ngClass]="{'unread-notification' : !notification.readAt}">
                                    <div class="product-list-item"
                                        (click)="accessNotification(notification, op , $event)"

                                    >

                                        <ng-template
                                                [ngIf]="notification.type === 'bookings'"
                                        >
                                            <ng-template
                                                    [ngIf]="( notification.data.status === 'planning-complete' || notification.data.status === 'studio-declined' || notification.data.status === 'studio-confirmed' || notification.data.status === 'studio-canceled')">
                                                <img [src]="notification.data.hasOwnProperty('studioLogo') ? notification.data.studioLogo : 'assets/images/bandbeat/sample_user_logo.png'"
                                                     [alt]="notification.type"/>
                                            </ng-template>
                                            <ng-template
                                                    [ngIf]="!(
                                                            notification.data.status === 'studio-declined' ||
                                                            notification.data.status === 'planning-complete' ||
                                                            notification.data.status === 'studio-confirmed' ||
                                                            notification.data.status === 'studio-canceled' ||
                                                            notification.data.category === 'bookingReviewReminder'
                                                            )">
                                                    <img
                                                            [src]="notification.sender.hasOwnProperty('avatar') ? notification.sender.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                                                            [alt]="notification.type"/>
                                            </ng-template>

                                            <ng-template
                                                [ngIf]="notification.data.category === 'bookingReviewReminder'"
                                            >
                                                <img
                                                    [src]="notification.data.hasOwnProperty('studioLogo') ? notification.data.studioLogo : 'assets/images/bandbeat/sample_user_logo.png'"
                                                    [alt]="notification.type"
                                                />
                                            </ng-template>


                                        </ng-template>

                                        <img
                                                *ngIf="notification.type === 'general'"
                                                [src]="'assets/images/sample_user.png'"
                                                [alt]="notification.type"
                                        />
                                        <img
                                                *ngIf="notification.type === 'invites'"
                                                [src]="notification.sender.hasOwnProperty('avatar') ? notification.sender.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                                                [alt]="notification.type"
                                        />
                                        <img
                                                *ngIf="
                                    notification.type !== 'bookings' &&
                                    notification.type !== 'invites' &&
                                    notification.type !== 'general'
                                "
                                                [src]="'assets/images/sample_user.png'"
                                                [alt]="notification.type"
                                        />
                                        <div class="product-list-detail" style="width: 250px;">
                                            <div *ngIf="notification.type == 'bookings'">

                                                <ng-template
                                                        [ngIf]="notification.data.category === 'newBookingNotification'"
                                                >
                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'planning'"
                                                    >
                                                        <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                        <span> requested a new </span>
                                                        <span class="keyword">{{notification.data.teamName}} {{notification.data.serviceType}}</span>
                                                        <span>! Review & respond!</span>
                                                    </ng-template>
                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'pending-confirmation'"
                                                    >
                                                        <ng-template
                                                                [ngIf]="notification.data.team"
                                                        >
                                                            <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.data.teamName}}</span>
                                                            <span> sent a </span>
                                                            <span class="keyword">{{notification.data.serviceType}}</span>
                                                            <span> request at </span>
                                                            <span>{{notification.data.studioName}}! We will let you know when {{notification.data.studioName}} responds!</span>
                                                        </ng-template>

                                                    </ng-template>
                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'studio-confirmed'"
                                                    >

                                        <span
                                                style="font-weight: 700;font-size: 16px;line-height: 20px;"
                                        >Your </span>
                                                        <span
                                                                *ngIf="notification.data.team"
                                                                style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.data.teamName}} </span>
                                                        <span class="keyword">{{notification.data.serviceType}} </span>
                                                        <span> request at {{notification.data.studioName}} was accepted!</span>
                                                    </ng-template>
                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'studio-declined' || notification.data.status === 'studio-canceled'"
                                                    >
                                            <span
                                                    style="font-weight: 700;font-size: 16px;line-height: 20px;"
                                            >Your </span>
                                                            <span
                                                                    *ngIf="notification.data.team"
                                                                    style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.data.teamName}} </span>
                                                            <span class="keyword">{{notification.data.serviceType}} </span>
                                                            <span> request at {{notification.data.studioName}} was {{notification.data.status === 'studio-declined' ? 'declined' : 'rejected'}}. Why not reschedule?</span>
                                                    </ng-template>

                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'user-declined'"
                                                    >
                                                        <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                        <span> just canceled </span>
                                                        <span
                                                                *ngIf="notification.data.team"
                                                                style="font-weight: 700;font-size: 16px;line-height: 20px;">the {{notification.data.teamName}} </span>
                                                        <span *ngIf="!notification.data.team">his/her </span>
                                                        <span class="keyword">{{notification.data.serviceType}}</span>
                                                        <span> booking!</span>
                                                    </ng-template>

                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'user-canceled'"
                                                    >
                                                        <span
                                                                style="font-weight: 700;font-size: 16px;line-height: 20px;"
                                                        >Your </span>
                                                        <span
                                                                *ngIf="notification.data.team"
                                                                style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.data.teamName}} </span>
                                                        <span class="keyword">{{notification.data.serviceType}} </span>
                                                        <span> at {{notification.data.studioName}} was canceled. Why not reschedule?</span>

                                                    </ng-template>

                                                    <ng-template [ngIf]="notification.data.status === 'planning-complete'">
                                                        <span>All members accepted your </span>
                                                        <span class="keyword">{{notification.data.teamName}} {{ notification.data.serviceType}} </span>
                                                        <span> request! Decide on your payment method & send the request to {{notification.data.studioName}}</span>
                                                    </ng-template>
                                                </ng-template>

                                                <ng-template
                                                        [ngIf]="notification.data.category === 'memberBookingResponseNotification'"
                                                >
                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'accept'"
                                                    >
                                                        <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                        <span> accepted your {{notification.data.teamName}}</span>
                                                        <span class="keyword"> {{notification.data.serviceType}}</span>
                                                        <span> request! </span>
                                                    </ng-template>
                                                    <ng-template
                                                            [ngIf]="notification.data.status === 'decline'"
                                                    >
                                                        <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                        <span> declined your {{notification.data.teamName}}</span>
                                                        <span class="keyword"> {{notification.data.serviceType}}</span>
                                                        <span> request! </span>
                                                        <span class="keyword">Why not reschedule?</span>
                                                    </ng-template>

                                                </ng-template>



                                                <ng-template
                                                    [ngIf]="notification.data.category === 'bookingReviewReminder'"
                                                >
                                                    <span >Hope you had a great time with your {{notification.data.serviceType}} at {{notification.data.studioName}} !</span>
                                                    <span> Feel free to share with us your booking </span>
                                                    <span class="keyword">review</span>
                                                </ng-template>

                                            </div>



                                            <div *ngIf="notification.type == 'invites'">
                                                <ng-template
                                                        [ngIf]="notification.data.status === 'pending'"
                                                >
                                                    <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                    <span> invited you to join </span>
                                                    <span class="keyword">{{notification.data.teamName}}</span>
                                                    <span>!</span>
                                                </ng-template>
                                                <ng-template
                                                        [ngIf]="notification.data.status === 'accepted'"
                                                >
                                                    <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                    <span> accepted your </span>
                                                    <span class="keyword">{{notification.data.teamName}}</span>
                                                    <span> invitation!</span>
                                                </ng-template>
                                                <ng-template
                                                        [ngIf]="notification.data.status === 'denied'"
                                                >
                                                    <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>
                                                    <span> declined your invitation to join </span>
                                                    <span class="keyword">{{notification.data.teamName}}</span>
                                                    <span>!</span>
                                                </ng-template>
                                            </div>


                                            <div *ngIf="notification.type == 'general'">
                                                <span>{{notification.message}}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </ng-template>


                            <ng-template
                                    pTemplate="footer"
                            >

                                <p-button
                                        label="Show All Notifications"
                                        (onClick)="accessNotifications(op, $event)"
                                        styleClass="tn-secondary-button"
                                        [style]="{'width':'100%'}"
                                ></p-button>

                            </ng-template>
                        </p-dataView>




                    </ng-template>
                </p-overlayPanel>

            </li>
            <li #profile style="border-radius: 50%;border:unset !important;" class="profile-item" [ngClass]="{'active-topmenuitem fadeInDown': appMain.activeTopbarItem === profile}">
                <div class="profile-container" (click)="appMain.onTopbarItemClick($event,profile)">
                    <img
                            alt="main logo"
                            [src]="userLogo ? userLogo['link'] : 'assets/images/sample_user.png'"
                            class="user-logo"
                    >

                </div>

                <ul class="profile-menu fadeInDown">

                    <li
                            class="mb-3"
                            *ngFor="let item of userMenu; let i = index;"
                    >
                        <a [routerLink]="item.link" href="#" (click)="appMain.onTopbarSubItemClick($event)">
                            <span>{{item.label}}</span>
                        </a>
                    </li>

                    <li>
                        <a href="#" (click)="appMain.onTopbarSubItemClick($event);logoutUser()">
                            <span>Sign Out</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>


    </div>


</div>

