
import {throwError as observableThrowError, Observable, BehaviorSubject, from} from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler,
  HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';

import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {filter , finalize, catchError, switchMap, take} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  constructor(
    private profileService: ProfileService,
    private authenticationService: AuthenticationService,
    private injector: Injector,
    private router: Router
  ) {}


  private addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    if (
        this.shouldIntercept(req.url)
    ) {
      if (!token) {
        try {
          token = this.authenticationService.getToken('access');
        } catch ( err ){
          this.router.navigate(['login']);
          return null;
        }
      }
      return req.clone(
        {
          setHeaders: {
            Authorization: 'Bearer ' + token
          }
        }
      );
    } else {
      return req;
    }
  }


  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(this.addToken(req))
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {

            if (
                (error as HttpErrorResponse).status === 401 &&
                error.url.endsWith('refresh-tokens')
            ) {
              this.profileService.logOutUser();
              window.location.assign(window.location.origin);
            }

            if ((error as HttpErrorResponse).status === 401 && !error.url.endsWith('auth/login')) {
              return this.handle401Error(req, next);
            } else if ((error as HttpErrorResponse).status === 400 && error.error.error === 'invalid_grant') {
              this.profileService.logOutUser();
              this.router.navigate(['login']);
            }
            return observableThrowError(error);
          } else {
            return observableThrowError(error);
          }
        })
      ) as Observable<any>;
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      return from(this.authenticationService.refreshToken())
        .pipe(
          finalize(() => {
            this.isRefreshingToken = false;
          }) ,
          catchError(error => {
            this.profileService.logOutUser();
            this.router.navigate(['login']);
            return next.handle(this.addToken(req));
          }) ,
          switchMap((newTokens: any) => {
            if (newTokens) {
              this.tokenSubject.next(newTokens.access.token);
              return next.handle(this.addToken(req, newTokens.access_token));
            }
            this.profileService.logOutUser();
            this.router.navigate(['login']);
            return null;
          })
        );
    } else {
      return this.tokenSubject
        .pipe(
          filter(token => token != null) ,
          take(1) ,
          switchMap(token => {
            return next.handle(this.addToken(req, token));
          }
        ));
    }
  }

  private shouldIntercept(url: string) {
    return ![
        '/auth/',
        'cardlink.gr',
        'ipapi.co',
        '/studio',
    ].some(element => {
      return url.includes(element);
    });
  }

}
