<div class="contact-container">
    <!-- Hero Section -->
    <div class="contact-hero">
        <h2>Let's Connect 🎸</h2>
        <p>We’d love to hear from you! Whether you need help, have a question, or just want to say hi – we’re always listening.</p>
    </div>

    <!-- Contact Cards -->
    <div class="contact-grid">
        <div class="contact-card">
            <i class="pi pi-check-circle"></i>
            <h3>Support</h3>
            <p>Need assistance? Our support team is here.</p>
            <a href="mailto:support@bandbeat.com">support@bandbeat.com</a>
        </div>

        <div class="contact-card">
            <i class="pi pi-heart"></i>
            <h3>Say Hello</h3>
            <p>Want to connect? Drop us a message!</p>
            <a href="mailto:hello@bandbeat.com">hello@bandbeat.com</a>
        </div>

        <div class="contact-card">
            <i class="pi pi-phone"></i>
            <h3>Call Us</h3>
            <p>Prefer a call? Reach us here.</p>
            <a href="tel:+30697-38-88-956">+30 697-3888-963</a>
        </div>
    </div>

    <!-- Social Media Links -->
    <div class="social-section">
        <h3>Join the Bandbeat Community</h3>
        <div class="social-links">
            <a href="https://www.facebook.com/bandbeat.official/" target="_blank" class="social-icon"><i class="till-noon-icon tnicon-Facebook"></i></a>
            <a href="https://www.instagram.com/bandbeat.official/" target="_blank"  class="social-icon"><i class="till-noon-icon tnicon-Instagram"></i></a>
            <a href="https://open.spotify.com/user/312gt67zju3rbtl65nlhdqe7gxhm?si=c822cf5f9c864c98/" target="_blank"  class="social-icon"><i class="till-noon-icon tnicon-Spotify"></i></a>
            <a href="https://www.linkedin.com/company/bandbeat/" target="_blank"  class="social-icon"><i class="till-noon-icon tnicon-Linkedin contact-button"></i></a>
        </div>
    </div>

    <!-- Footer with Logo -->
    <div class="logo-bottom">
        <img src="../../../assets/images/bandbeat/bb_logo_header.png" alt="Bandbeat Logo"/>
        <p>The Bandbeat Team</p>
    </div>
</div>
